@import '../../style/themes/index';
@import '../../style/mixins/index';

@divider-prefix-cls: ~'@{ant-prefix}-divider';

.@{divider-prefix-cls} {
  .reset-component;

  background: @border-color-split;

  &,  /* for compatiable */
  &-vertical {
    position: relative;
    top: -0.06em;
    display: inline-block;
    width: 1px;
    height: 0.9em;
    margin: 0 8px;
    vertical-align: middle;
  }

  &-horizontal {
    display: block;
    clear: both;
    width: 100%;
    min-width: 100%; // Fix https://github.com/ant-design/ant-design/issues/10914
    height: 1px;
    margin: 24px 0;
  }

  &-horizontal&-with-text-center,
  &-horizontal&-with-text-left,
  &-horizontal&-with-text-right {
    display: table;
    margin: 16px 0;
    color: @heading-color;
    font-weight: 500;
    font-size: @font-size-lg;
    white-space: nowrap;
    text-align: center;
    background: transparent;
    &::before,
    &::after {
      position: relative;
      top: 50%;
      display: table-cell;
      width: 50%;
      border-top: 1px solid @border-color-split;
      transform: translateY(50%);
      content: '';
    }
  }

  &-horizontal&-with-text-left,
  &-horizontal&-with-text-right {
    .@{divider-prefix-cls}-inner-text {
      display: inline-block;
      padding: 0 10px;
    }
  }

  &-horizontal&-with-text-left {
    &::before {
      top: 50%;
      width: 5%;
    }
    &::after {
      top: 50%;
      width: 95%;
    }
  }

  &-horizontal&-with-text-right {
    &::before {
      top: 50%;
      width: 95%;
    }
    &::after {
      top: 50%;
      width: 5%;
    }
  }

  &-inner-text {
    display: inline-block;
    padding: 0 24px;
  }

  &-dashed {
    background: none;
    border-color: @border-color-split;
    border-style: dashed;
    border-width: 1px 0 0;
  }

  &-horizontal&-with-text-center&-dashed,
  &-horizontal&-with-text-left&-dashed,
  &-horizontal&-with-text-right&-dashed {
    border-top: 0;
    &::before,
    &::after {
      border-style: dashed none none;
    }
  }

  &-vertical&-dashed {
    border-width: 0 0 0 1px;
  }
}

@primary-color: #106BA3;@info-color: #106BA3;@success-color: #0F9960;@processing-color: #106BA3;@error-color: #C23030;@highlight-color: #DB3737;@warning-color: #D9822B;@normal-color: #d9d9d9;@white: #ffffff;@black: #10161A;@font-family: 'Lato', sans-serif;@font-size-base: 16px;@text-color: #10161A;@layout-body-background: #f7f7f7;@layout-header-padding: 0px 0px 0px 0px;@layout-footer-padding: 0px 0px 0px 0px;@layout-header-background: #182026;@menu-dark-item-active-bg: #394B59;@layout-trigger-background: #293742;@menu-dark-submenu-bg: transparent;@body-background: #ffffff;@component-background: #ffffff;@bodyHeight: calc(100vh - 64px);@bodyHeightSideCol: calc(100vh - 65px);@primaryColor: #106BA3;@backgroundColor: #f7f7f7;@black: #10161A;@lightGreyVariant1: #D8E1E8;@lightGreyVariant2: #EBF1F5;@textColorSecondary: rgba(105,105,105,1);@bookingStatusColors: [object Object];@tagColors: [object Object];@workerStatusColors: [object Object];